import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				About | DEUTSCHE SPORTWELT PUB
			</title>
			<meta name={"description"} content={"Discover the heart of Deutsche Sportwelt Pub, where tales of athletic glory meet the rich tapestry of German culture."} />
			<meta property={"og:title"} content={"About | DEUTSCHE SPORTWELT PUB"} />
			<meta property={"og:description"} content={"Discover the heart of Deutsche Sportwelt Pub, where tales of athletic glory meet the rich tapestry of German culture."} />
			<meta property={"og:image"} content={"none"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"} type={"image/x-icon"} />
			<meta name={"msapplication-TileColor"} content={"https://uploads.quarkly.io/652eb29ddbe26f0020fcdf99/images/pub-location-icon-free-vector.jpg?v=2023-10-17T16:23:32.147Z"} />
		</Helmet>
		<Components.Header />
		<Section text-align="center" padding="80px 0" sm-padding="40px 0">
			<Text as="h1" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				ABOUT US
			</Text>
			<Text as="p" font="--lead" margin="20px 0 0 0">
				In the heart of an age-old city, stands Deutsche Sportwelt Pub, a sanctuary that goes beyond mere entertainment. It's where legends come alive, where every corner whispers tales of athletic glory, and where the beauty of German culture intertwines seamlessly with the thrill of sports.{"\n\n"}
				<br />
				<br />
				Born from the dreams of an unnamed wanderer, Deutsche Sportwelt Pub finds its origins in an ancient gymnasium. This space, which once echoed with the grunts of training athletes and the cheers of spectators, has been reborn as a pub where past and present meld.
Over the years, the pub has grown to be more than just a drinking hole. It has become a haven for those who seek stories, who long to experience the adrenaline of the game, and who cherish the intricate tapestry of German traditions.{"\n\n"}
			</Text>
			<Box min-width="100px" min-height="100px">
				<Button
					display="inline-block"
					width="150px"
					text-align="center"
					margin="20px 0px 0px 0px"
					type="link"
					text-decoration-line="initial"
					href="/contact"
					background="--color-orange"
				>
					Contact us
				</Button>
			</Box>
			<Box display="flex" margin="40px 0 20px 0" justify-content="space-around" sm-flex-direction="column">
				<Box padding="10px">
					<Image
						src="https://uploads.quarkly.io/652eb29ddbe26f0020fcdf99/images/weitress_2.1%20%282%29.jpg?v=2023-10-18T08:24:55.898Z"
						width="320px"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/652eb29ddbe26f0020fcdf99/images/weitress_2.1%20%282%29.jpg?v=2023-10-18T08%3A24%3A55.898Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/652eb29ddbe26f0020fcdf99/images/weitress_2.1%20%282%29.jpg?v=2023-10-18T08%3A24%3A55.898Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/652eb29ddbe26f0020fcdf99/images/weitress_2.1%20%282%29.jpg?v=2023-10-18T08%3A24%3A55.898Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/652eb29ddbe26f0020fcdf99/images/weitress_2.1%20%282%29.jpg?v=2023-10-18T08%3A24%3A55.898Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/652eb29ddbe26f0020fcdf99/images/weitress_2.1%20%282%29.jpg?v=2023-10-18T08%3A24%3A55.898Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/652eb29ddbe26f0020fcdf99/images/weitress_2.1%20%282%29.jpg?v=2023-10-18T08%3A24%3A55.898Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/652eb29ddbe26f0020fcdf99/images/weitress_2.1%20%282%29.jpg?v=2023-10-18T08%3A24%3A55.898Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
				</Box>
				<Box padding="10px">
					<Image
						src="https://uploads.quarkly.io/652eb29ddbe26f0020fcdf99/images/weitress_2.2%20%281%29.jpg?v=2023-10-18T08:24:55.917Z"
						width="320px"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/652eb29ddbe26f0020fcdf99/images/weitress_2.2%20%281%29.jpg?v=2023-10-18T08%3A24%3A55.917Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/652eb29ddbe26f0020fcdf99/images/weitress_2.2%20%281%29.jpg?v=2023-10-18T08%3A24%3A55.917Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/652eb29ddbe26f0020fcdf99/images/weitress_2.2%20%281%29.jpg?v=2023-10-18T08%3A24%3A55.917Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/652eb29ddbe26f0020fcdf99/images/weitress_2.2%20%281%29.jpg?v=2023-10-18T08%3A24%3A55.917Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/652eb29ddbe26f0020fcdf99/images/weitress_2.2%20%281%29.jpg?v=2023-10-18T08%3A24%3A55.917Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/652eb29ddbe26f0020fcdf99/images/weitress_2.2%20%281%29.jpg?v=2023-10-18T08%3A24%3A55.917Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/652eb29ddbe26f0020fcdf99/images/weitress_2.2%20%281%29.jpg?v=2023-10-18T08%3A24%3A55.917Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
				</Box>
				<Box padding="10px">
					<Image
						src="https://uploads.quarkly.io/652eb29ddbe26f0020fcdf99/images/weitress_2.4%20%281%29.jpg?v=2023-10-18T08:24:55.932Z"
						width="320px"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/652eb29ddbe26f0020fcdf99/images/weitress_2.4%20%281%29.jpg?v=2023-10-18T08%3A24%3A55.932Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/652eb29ddbe26f0020fcdf99/images/weitress_2.4%20%281%29.jpg?v=2023-10-18T08%3A24%3A55.932Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/652eb29ddbe26f0020fcdf99/images/weitress_2.4%20%281%29.jpg?v=2023-10-18T08%3A24%3A55.932Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/652eb29ddbe26f0020fcdf99/images/weitress_2.4%20%281%29.jpg?v=2023-10-18T08%3A24%3A55.932Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/652eb29ddbe26f0020fcdf99/images/weitress_2.4%20%281%29.jpg?v=2023-10-18T08%3A24%3A55.932Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/652eb29ddbe26f0020fcdf99/images/weitress_2.4%20%281%29.jpg?v=2023-10-18T08%3A24%3A55.932Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/652eb29ddbe26f0020fcdf99/images/weitress_2.4%20%281%29.jpg?v=2023-10-18T08%3A24%3A55.932Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Testimonials>
			<Override slot="text" />
		</Components.Testimonials>
		<Components.Footer />
	</Theme>;
});